@import "../Common/variables.module.scss";

$image-size: 130px;
$corner-banner-height: 35px;

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    width: 100%;
    min-width: calc(350px - $padding * 2);
    background-image: $paper-background;
}

.cursorPointer {
    cursor: pointer;
}

.textContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: $padding;
    padding-top: calc($padding / 2) + $corner-banner-height;
    width: 100%;
}

.image {
    width: $image-size;
    height: $image-size;
    object-fit: cover;
    display: inline-block;
}

.topCorner {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba($color: #d7eaf3, $alpha: 1) !important;
    color: #062c30 !important;
    padding-left: $padding;
    padding-right: $padding;
    height: $corner-banner-height;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.transparent {
    opacity: 0.3;
}

.upDownButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.divider {
    margin-left: $padding !important;
    margin-right: $padding !important;
}
