@import "../Common/variables.module.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent !important;
    margin: $padding;
    justify-content: flex-start;
    gap: $flex-gap;

    &.fullscreen{
        width: 100%;
        @media (min-width: 1600px) {
            max-width: 25%;
        }
    }
}

.textbox {
    width: 80%;
}

.lowerContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $flex-gap;
}

.resize {
    font-size: 25px !important;
}
