@import "../Common/variables.module.scss";

.outterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $flex-gap;
}

.currentNextContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 500px;
    padding: $padding;
    gap: $flex-gap;
}

.queueContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $padding;
    gap: $flex-gap;
}

.noPadding {
    padding: 0px;
}
