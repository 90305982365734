@import "../Common/variables.module.scss";

.sideContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: $padding;
    padding: $padding;
    background-color: rgba($color: #000000, $alpha: 0.3);
}

.paper {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    background-image: $paper-background;
    max-width: 600px;
}

.icon {
    width: 50px !important;
    height: 50px !important;
}

.infoContainer {
    padding: $padding;
}
