@import "../Common/variables.module.scss";

.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: $padding;
    gap: $flex-gap;
    justify-content: space-around;
    align-items: center;
}

.buttonContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: $padding;

    width: 100%;
}

.button{
    width: 250px;
}